import React, { Fragment, useEffect, useState } from "react";
import { Card, CardBody, Container, Row } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { Box, Button, MenuItem, Typography } from "@mui/material";
import { Grid, TextField } from "@mui/material";
import {
  PersonSearch,
  Refresh,
  SimCardDownloadRounded,
} from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { Col } from "react-bootstrap";
import moment from "moment";

import {
  getAllAdjustmentReport,
  searchAdjustmentReportData,
  exportToExcelAdjustmentReport,
  getConsolidateAdjustmentMonthlyReport,
  updateRevisedCdAmount,
} from "../../api/service";

import CommonLoader from "../../common/commonLoader";
import Breadcrumb from "../../common/breadcrumb";
import {
  CardAdjustmentReportValue,
  adjustmentReportTableDataAdminDetailedView,
  adjustmentReportTableDataAdminMainView,
  adjustmentReportTableHeaderAdminDetailedView,
  adjustmentReportTableHeaderAdminMainView,
} from "../../common/commonCardConstant";
import CommonCardDesign from "../../common/commonCardDesign";
import { useCustomerCode } from "../../context/CustomerCodeProvider";
import { formatMoney, getMonthName } from "../../../constants/utils";
import { isEmpty } from "lodash";
import CommonDialog from "../../common/commonDialog";

const useStyles = makeStyles((theme) => ({
  btnClass: {
    padding: "0.5rem 1.5rem!important",
    fontWeight: 600,
  },

  btnJust: {
    display: "flex!important",
    justifyContent: "end!important",
  },

  cardbody: {
    padding: `${theme.spacing(1.8, 1.3)} !important`,
    [theme.breakpoints.down("sm")]: {
      padding: `${theme.spacing(0)} !important`,
      margin: `${theme.spacing(0, 1)} !important`,
    },
  },

  cardBodyMonthly: {
    padding: `${theme.spacing(0.75)} !important`,
  },

  muiGrid: {
    marginTop: "-2vh",
    [theme.breakpoints.down("sm")]: {
      lineHeight: 5,
    },
  },
}));

const AdjustmentReport = () => {
  const classes = useStyles();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(1000);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [consolidateData, setConsolidateData] = useState([]);
  const { selectedCustomerCode, selectedCustomerName } = useCustomerCode();
  const [cdRevisedAmount, setCdRevisedAmount] = useState(null);
  const [selectedAdjustmentId, setSelectedAdjustmentId] = useState(null);
  const filterInitialValues = {
    searchFinancialYear: "FY 2024-25",
    searchMonth: "DM",
  };

  const isAdminUser =
    JSON.parse(sessionStorage.getItem("userData")).groupId === 1;

  const [searchData, setSearchData] = useState(filterInitialValues);
  const [showDetailedView, setShowDetailedView] = useState(false);

  const months = [
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "01",
    "02",
    "03",
  ];
  //const currentDate = new Date();
  //const currentMonth = currentDate.getMonth() + 1;

  const getMonthMenuItems = () => {
    if (searchData.searchFinancialYear === "FY 2023-24") {
      return [
        <MenuItem key="01" value="01">
          January
        </MenuItem>,
        <MenuItem key="02" value="02">
          February
        </MenuItem>,
        <MenuItem key="03" value="03">
          March
        </MenuItem>,
      ];
    } else if (searchData.searchFinancialYear === "FY 2024-25") {
      //const previousMonth = currentMonth - 1;
      return [
        <MenuItem value="DM">During Month</MenuItem>,
        <MenuItem value="04">April</MenuItem>,
        <MenuItem value="05">May</MenuItem>,
        <MenuItem value="06">June</MenuItem>,
        <MenuItem value="07">July</MenuItem>,
        <MenuItem value="08">August</MenuItem>,
        <MenuItem value="09">September</MenuItem>,
        <MenuItem value="10">October</MenuItem>,
        <MenuItem value="11">November</MenuItem>,
        <MenuItem value="12">December</MenuItem>,
        <MenuItem value="01">January</MenuItem>,
        <MenuItem value="02">February</MenuItem>,
      ];
    } /* else {
      return (
        <>
          <MenuItem value="DM">During Month</MenuItem>
          <MenuItem value="01">January</MenuItem>
          <MenuItem value="02">February</MenuItem>
          <MenuItem value="03">March</MenuItem>
          <MenuItem value="04">April</MenuItem>
          <MenuItem value="05">May</MenuItem>
          <MenuItem value="06">June</MenuItem>
          <MenuItem value="07">July</MenuItem>
          <MenuItem value="08">August</MenuItem>
          <MenuItem value="09">September</MenuItem>
          <MenuItem value="10">October</MenuItem>
          <MenuItem value="11">November</MenuItem>
          <MenuItem value="12">December</MenuItem>
        </>
      );
    } */
  };
  useEffect(() => {
    filterData();
  }, [selectedCustomerCode]);

  const onFilterInputChange = (event) => {
    const { name, value } = event.target;
    searchData[name] = value;
    setSearchData({ ...searchData });
  };
  /* 
  useEffect(() => {
    loadListData();
  }, []); */

  const loadListData = () => {
    setLoading(true);
    getAllAdjustmentReport("", page, rowsPerPage).then((res) => {
      setLoading(false);
      setData(res.response.responseObject);
      //setConsolidateData(res.response.responseMap.consolidateList);
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const filterData = () => {
    getConsolidateAdjustmentMonthlyReport(
      selectedCustomerCode,
      searchData.searchFinancialYear
    ).then((res) => {
      setConsolidateData(res.response.responseObject);
    });

    searchAdjustmentReportData(
      selectedCustomerCode,
      searchData,
      page,
      rowsPerPage
    ).then((res) => {
      setData(res.responseMap.data);
    });
  };

  const getAmount = (month) => {
    let amount = "-";

    const monthlyData = consolidateData.find(
      (item) => item.uploadedMonth === month
    );
    if (monthlyData && !isEmpty(monthlyData)) {
      amount = `₹ ${formatMoney(
        monthlyData.totalCdReceivedAmount?.toFixed(0)
      )}`;
    }
    return amount;
  };
  const resetData = () => {
    getConsolidateAdjustmentMonthlyReport(
      selectedCustomerCode,
      searchData.searchFinancialYear
    ).then((res) => {
      setConsolidateData(res.response.responseObject);
    });
    searchAdjustmentReportData(
      selectedCustomerCode,
      filterInitialValues,
      page,
      rowsPerPage
    ).then((res) => {
      setData(res.responseMap.data);
    });
  };

  const exportData = () => {
    setLoading(true);
    exportToExcelAdjustmentReport(
      selectedCustomerCode,
      `${selectedCustomerName.replace(" ", "_")}_${moment(new Date()).format(
        "DD_MM_YYYY_HH_mm_SS"
      )}`,
      searchData
    ).then(() => {
      setLoading(false);
    });
  };

  const onOpenModal = (row) => {
    setOpenModal(true);
    setSelectedAdjustmentId(row.adjustmentReportId);
  };

  const handleClose = () => {
    setOpenModal(false);
    setSelectedAdjustmentId(null);
    setCdRevisedAmount(null);
  };

  const handleSubmit = () => {
    updateRevisedCdAmount(
      searchData.searchMonth,
      selectedAdjustmentId,
      cdRevisedAmount
    ).then((res) => {
      toast.success("Successfully Updated !", { autoClose: 1000 });
      handleClose();
      filterData();
    });
    /* saveBalanceConfirmationMobile(data).then((res) => {
        setOpenPinDialog(false);
        handleClose();
      }); */
    /*  if (data.balanceConfirmationStatus === "Agree") {
        setOpenVerificationDialog(true);
        // handleClose();
      } else {
        setOpenVerificationDialog(true);
      } */
  };

  return (
    <Fragment>
      {loading ? <CommonLoader /> : null}
      <Breadcrumb title="Adjustment Report" />
      {/* <!-- Container-fluid starts--> */}
      <Container fluid={true}>
        <Row>
          {/* <CommonAccordion accordionTitle={"Advanced Search"}>
            <Grid container columnSpacing={2} className={classes.muiGrid}>
              <Grid item sm={4} xs={12}>
                <TextField
                  fullWidth
                  label="Customer Code"
                  size="small"
                  select
                  id="fullWidth"
                  name="searchCustomerCode"
                  value={searchData.searchCustomerCode}
                  onChange={onFilterInputChange}
                ></TextField>
              </Grid>

              <Grid item sm={4} xs={12}>
                <TextField
                  fullWidth
                  id="outlined-select-currency"
                  select
                  label="Invoice Number"
                  size="small"
                  name="searchInvoiceNumber"
                  value={searchData.searchInvoiceNumber}
                  onChange={onFilterInputChange}
                ></TextField>
              </Grid>

              <Grid
                item
                sm={4}
                xs={12}
                sx={{
                  margin: "auto",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  color="primary"
                  variant="contained"
                  type="button"
                  className={classes.btnClass}
                  onClick={() => filterData()}
                >
                  <PersonSearch></PersonSearch>
                </Button>
                <Button
                  color="primary"
                  sx={{ marginLeft: 1 }}
                  variant="contained"
                  type="button"
                  className={classes.btnClass}
                  onClick={() => {
                    setSearchData(filterInitialValues);
                    loadListData();
                  }}
                >
                  <Refresh></Refresh>
                </Button>
              </Grid>
            </Grid>
          </CommonAccordion> */}

          <Col lg={12} xs={12}>
            <Card>
              <CardBody className={classes.cardbody}>
                <Grid container spacing={1.5} className={classes.muiGrid}>
                  {/* <Grid item sm={6} xs={12}>
                    <TextField
                      fullWidth
                      id="outlined-select-currency"
                      label="Invoice Number"
                      size="small"
                      name="searchInvoiceNumber"
                      value={searchData.searchInvoiceNumber}
                      onChange={onFilterInputChange}
                    ></TextField>
                  </Grid> */}

                  <Grid item sm={4} xs={12}>
                    <TextField
                      fullWidth
                      label="Financial Year"
                      size="small"
                      select
                      id="fullWidth"
                      name="searchFinancialYear"
                      value={searchData.searchFinancialYear}
                      onChange={onFilterInputChange}
                    >
                      <MenuItem value="FY 2023-24">FY 2023-24</MenuItem>
                      <MenuItem value="FY 2024-25">FY 2024-25</MenuItem>
                    </TextField>
                  </Grid>

                  <Grid item sm={4} xs={12}>
                    <TextField
                      fullWidth
                      label="Month"
                      size="small"
                      select
                      id="fullWidth"
                      name="searchMonth"
                      value={searchData.searchMonth}
                      onChange={(e) => onFilterInputChange(e)}
                    >
                      {getMonthMenuItems()}
                    </TextField>
                  </Grid>

                  {/*  <Grid item sm={6} xs={12}>
                    <TextField
                      fullWidth
                      label="During Month"
                      size="small"
                      select
                      id="fullWidth"
                      name="searchDuringMonth"
                      value={searchData.searchDuringMonth}
                      onChange={onFilterInputChange}
                    >
                      
                      <MenuItem value={moment().format("MM")}>
                        {moment().format("MMMM")}
                      </MenuItem>
                    </TextField>
                  </Grid> */}

                  <Grid
                    item
                    sm={4}
                    xs={12}
                    sx={{
                      margin: "auto",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      type="button"
                      className={classes.btnClass}
                      onClick={() => filterData()}
                    >
                      <PersonSearch></PersonSearch>
                    </Button>
                    <Button
                      color="primary"
                      sx={{ marginLeft: 1 }}
                      variant="contained"
                      type="button"
                      className={classes.btnClass}
                      onClick={() => {
                        setSearchData({ ...filterInitialValues });
                        resetData();
                      }}
                    >
                      <Refresh></Refresh>
                    </Button>
                    <Button
                      color="primary"
                      sx={{ marginLeft: 1 }}
                      variant="contained"
                      type="button"
                      className={classes.btnClass}
                      onClick={() => {
                        exportData();
                      }}
                    >
                      <SimCardDownloadRounded></SimCardDownloadRounded>
                    </Button>
                  </Grid>
                </Grid>
              </CardBody>
            </Card>
          </Col>

          <Col lg={12} xs={12}>
            <Grid container spacing={1} className={classes.muiGrid}>
              {months.map((month) => (
                <Grid item sm={2} xs={6}>
                  <Card style={{ marginBottom: 4, background: "#dbdbdb" }}>
                    <CardBody className={classes.cardBodyMonthly}>
                      <Typography color={"black"} textAlign={"center"}>
                        {getMonthName(month)}
                      </Typography>
                      <Typography color={"red"} textAlign={"center"}>
                        {getAmount(month)}
                      </Typography>
                    </CardBody>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Col>

          {/* <Col lg={6} xs={12}>
            <Card className="height-equal">
              <CardBody className={classes.cardbody}>
                <div className="user-status table-responsive products-table">
                  <table className="table table-bordernone mb-0">
                    <thead>
                      <tr>
                        <th scope="col">Month</th>
                        <th scope="col">CD Received Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {consolidateData.map((data) => (
                        <tr>
                          <td>{getMonthName(data.uploadedMonth)}</td>
                          <td className="digits">
                            {formatMoney(
                              data.totalCdReceivedAmount?.toFixed(0)
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="user-status table-responsive products-table">
                  <table className="table table-bordernone mb-0">
                    <thead>
                      <tr>
                        <th scope="col">Customer Code</th>
                        <th scope="col">Posting Date</th>
                        <th scope="col">Receipt/CN</th>
                        <th scope="col">Balance/Excess</th>
                      </tr>
                    </thead>
                    <tbody>
                      {consolidateData.map((data) => (
                        <tr>
                          <td>{data.customerCode}</td>
                          <td className="digits">{data.postingDate}</td>
                          <td className="digits">
                            {data.receiptCn?.toFixed(0)}
                          </td>
                          <td className="digits">
                            {data.balanceExcess?.toFixed(0)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </Col> */}

          <CardBody className={classes.cardbody}>
            <div>
              {/* <CommonTable
                  tableData={data}
                  tableHeaders={adjustmentReportTableHeader}
                  tableColumns={adjustmentReportTableData}
                  rowsPerPageOptions={[25, 50, 100]}
                  count={data.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  sx={{ float: "right" }}
                  displayPagination={false}
                /> */}
              <Box display={"flex"}>
                <Typography variant="h5">{`${selectedCustomerName} - ${selectedCustomerCode}`}</Typography>
                <Button
                  style={{ marginLeft: 10, marginBottom: 4 }}
                  color="primary"
                  variant="contained"
                  type="button"
                  onClick={() => setShowDetailedView(!showDetailedView)}
                >
                  {`View ${!showDetailedView ? "Detailed" : "Main"} data`}
                </Button>
              </Box>
              <CommonCardDesign
                tableData={data}
                tableHeaders={
                  //isAdminUser?
                  showDetailedView
                    ? adjustmentReportTableHeaderAdminDetailedView
                    : adjustmentReportTableHeaderAdminMainView
                  //  : adjustmentReportTableHeader1
                }
                tableColumns={
                  //isAdminUser?
                  showDetailedView
                    ? adjustmentReportTableDataAdminDetailedView
                    : adjustmentReportTableDataAdminMainView
                  // : adjustmentReportTableData1
                }
                tableModal={CardAdjustmentReportValue}
                rowsPerPageOptions={[25, 50, 100]}
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{ float: "right" }}
                displayPagination={false}
                isAdjustmentDetails
                showDetailedView={showDetailedView}
                isAdminUser={isAdminUser && searchData.searchMonth != "DM"}
                onOpenModal={onOpenModal}
              />
            </div>
          </CardBody>

          {/* <Card>
            <CardBody className={classes.cardbody}>
              <div>
                <CommonTable
                  tableData={consolidateData}
                  tableHeaders={adjustmentReportConsolidateTableHeader}
                  tableColumns={adjustmentReportConsolidateTableData}
                  rowsPerPageOptions={[25, 50, 100]}
                  count={consolidateData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  sx={{ float: "right" }}
                  displayPagination={false}
                />
              </div>
            </CardBody>
          </Card> */}
        </Row>
      </Container>
      {/* <!-- Container-fluid Ends--> */}
      <ToastContainer autoClose={1000} />

      <CommonDialog
        open={openModal}
        title="Update CD Received Amount"
        onCancel={handleClose}
        primaryButtonLabel={"Submit"}
        onSave={handleSubmit}
      >
        <Grid container spacing={2} style={{ margin: "5px 0", width: "80%" }}>
          <Grid item sm={6} xs={12}>
            <TextField
              fullWidth
              id="outlined-search"
              label="CD Revised Amount"
              name="cdRevisedAmount"
              type="number"
              size="small"
              value={cdRevisedAmount}
              onChange={(event) => {
                setCdRevisedAmount(event.target.value);
              }}
            />
          </Grid>
        </Grid>
      </CommonDialog>
    </Fragment>
  );
};

export default AdjustmentReport;
