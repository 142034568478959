import React, { Fragment } from "react";
import { Route, Routes } from "react-router-dom";
import App from "../components/app";
import Datatable from "../components/common/datatable";
import Createcoupons from "../components/coupons/create-coupons";
import ListCoupons from "../components/coupons/list-coupons";
import Dashboard from "../components/dashboard";
import Invoice from "../components/invoice";
import Rates from "../components/localization/rates";
import Taxes from "../components/localization/taxes";
import Translations from "../components/localization/translations";
import Media from "../components/media/media";
import Createmenu from "../components/menus/create-menu";
import Listmenu from "../components/menus/list-menu";
import Createpage from "../components/pages/create-page";
import ListPages from "../components/pages/list-page";
import Digitaladdpro from "../components/products/digital/digital-add-pro";
import Digitalcategory from "../components/products/digital/digital-category";
import Digitalprolist from "../components/products/digital/digital-pro-list";
import Digitalsubcategory from "../components/products/digital/digital-sub-category";
import Addproduct from "../components/products/physical/add-product";
import Category from "../components/products/physical/category";
import Productdetail from "../components/products/physical/product-detail";
import Productlist from "../components/products/physical/product-list";
import Subcategory from "../components/products/physical/sub-category";
import Reports from "../components/reports/report";
import Orders from "../components/sales/orders";
import Transactionsales from "../components/sales/transactions-sales";
import Profile from "../components/settings/profile";
import Createuser from "../components/users/create-user";
import Listuser from "../components/users/list-user";
import Createvendors from "../components/vendors/create.vendors";
import Listvendors from "../components/vendors/list-vendors";
import Users from "../components/userManagement/user/users";
import RegionMaster from "../components/master/regionMaster/regionMaster";
import Group from "../components/userManagement/group/group";
import StateMaster from "../components/master/stateMaster/stateMaster";
import DivisionMaster from "../components/master/divisionMaster/divisionMaster";
import DistributorMaster from "../components/master/distributorMaster/distributorMaster";
import OutstandingBill from "../components/reports/outstandingBill/outstandingBill";
import OpenDebit from "../components/reports/openDebit/openDebit";
import OpenCredit from "../components/reports/openCredit/openCredit";
import AdjustmentReport from "../components/reports/adjustmentReport/adjustmentReport";
import CreditExtension from "../components/master/creditExtension/creditExtension";
import BalanceConfirmation from "../components/master/balanceConfirmation/balanceConfirmation";
import PolicyMaster from "../components/master/policyMaster/policyMaster";
import Ndc from "../components/master/ndc/ndc";
import ReasonMaster from "../components/master/reasonMaster/reasonMaster";
import AddressMaster from "../components/master/addressMaster/addressMaster";
import DistributorMapping from "../components/master/distributorMapping/distributorMapping";
import BalanceConfirmationReport from "../components/master/balanceConfirmationReport/balanceConfirmationReport";
import ChangePassword from "../components/settings/changePassword";
import UserLog from "../components/userManagement/userLog/userLog";
import OpenCreditHistory from "../components/reports/openCredit/openCreditHistory";
import OpenDebitHistory from "../components/reports/openDebit/openDebitHistory";
import Tds194RDeclaration from "../components/master/tds194RDeclaration/tds194RDeclaration";
import Ledger from "../components/reports/ledger/ledger";
import Tds194RDeclarationReport from "../components/master/tds194RDeclarationReport/tds194RDeclarationReport";
import BalanceConfirmationHistory from "../components/master/balanceConfirmationHistory/balanceConfirmationHistory";
import AgeingReport from "../components/reports/ageingReport/ageingReport";

const LayoutRoutes = () => {
  return (
    <Fragment>
      <Routes>
        <Route element={<App />}>
          <Route
            path={`${process.env.PUBLIC_URL}/dashboard`}
            element={<Dashboard />}
          />

          <Route
            path={`${process.env.PUBLIC_URL}/products/physical/category`}
            element={<Category />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/products/physical/sub-category`}
            element={<Subcategory />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/products/physical/product-list`}
            element={<Productlist />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/products/physical/product-detail`}
            element={<Productdetail />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/products/physical/add-product`}
            element={<Addproduct />}
          />

          <Route
            path={`${process.env.PUBLIC_URL}/products/digital/digital-category`}
            element={<Digitalcategory />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/products/digital/digital-sub-category`}
            element={<Digitalsubcategory />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/products/digital/digital-product-list`}
            element={<Digitalprolist />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/products/digital/digital-add-product`}
            element={<Digitaladdpro />}
          />

          <Route
            path={`${process.env.PUBLIC_URL}/sales/orders`}
            element={<Orders />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/sales/transactions`}
            element={<Transactionsales />}
          />

          <Route
            path={`${process.env.PUBLIC_URL}/coupons/list-coupons`}
            element={<ListCoupons />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/coupons/create-coupons`}
            element={<Createcoupons />}
          />

          <Route
            path={`${process.env.PUBLIC_URL}/pages/list-page`}
            element={<ListPages />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/pages/create-page`}
            element={<Createpage />}
          />

          <Route path={`${process.env.PUBLIC_URL}/media`} element={<Media />} />

          <Route
            path={`${process.env.PUBLIC_URL}/menus/list-menu`}
            element={<Listmenu />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/menus/create-menu`}
            element={<Createmenu />}
          />

          <Route
            path={`${process.env.PUBLIC_URL}/users/list-user`}
            element={<Listuser />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/users/create-user`}
            element={<Createuser />}
          />

          <Route
            path={`${process.env.PUBLIC_URL}/vendors/list_vendors`}
            element={<Listvendors />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/vendors/create-vendors`}
            element={<Createvendors />}
          />

          <Route
            path={`${process.env.PUBLIC_URL}/localization/transactions`}
            element={<Translations />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/localization/currency-rates`}
            element={<Rates />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/localization/taxes`}
            element={<Taxes />}
          />

          <Route
            path={`${process.env.PUBLIC_URL}/reports/report`}
            element={<Reports />}
          />

          <Route
            path={`${process.env.PUBLIC_URL}/settings/profile`}
            element={<Profile />}
          />

          <Route
            path={`${process.env.PUBLIC_URL}/settings/changePassword`}
            element={<ChangePassword />}
          />

          <Route
            path={`${process.env.PUBLIC_URL}/invoice`}
            element={<Invoice />}
          />

          <Route
            path={`${process.env.PUBLIC_URL}/data-table`}
            element={<Datatable />}
          />

          <Route
            path={`${process.env.PUBLIC_URL}/userManagement/users`}
            element={<Users />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/master/regionMaster`}
            element={<RegionMaster />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/userManagement/group`}
            element={<Group />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/master/stateMaster`}
            element={<StateMaster />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/master/divisionMaster`}
            element={<DivisionMaster />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/master/distributorMaster`}
            element={<DistributorMaster />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/reports/outstandingBill`}
            element={<OutstandingBill />}
          />

          <Route
            path={`${process.env.PUBLIC_URL}/reports/openDebit`}
            element={<OpenDebit />}
          />

          <Route
            path={`${process.env.PUBLIC_URL}/reports/openDebitHistory`}
            element={<OpenDebitHistory />}
          />

          <Route
            path={`${process.env.PUBLIC_URL}/reports/openCredit`}
            element={<OpenCredit />}
          />

          <Route
            path={`${process.env.PUBLIC_URL}/reports/openCreditHistory`}
            element={<OpenCreditHistory />}
          />

          <Route
            path={`${process.env.PUBLIC_URL}/reports/adjustmentReport`}
            element={<AdjustmentReport />}
          />

          <Route
            path={`${process.env.PUBLIC_URL}/master/creditExtension`}
            element={<CreditExtension />}
          />

          <Route
            path={`${process.env.PUBLIC_URL}/master/balanceConfirmation`}
            element={<BalanceConfirmation />}
          />

          <Route
            path={`${process.env.PUBLIC_URL}/master/policyMaster`}
            element={<PolicyMaster />}
          />

          <Route
            path={`${process.env.PUBLIC_URL}/master/ndc`}
            element={<Ndc />}
          />

          <Route
            path={`${process.env.PUBLIC_URL}/master/reasonMaster`}
            element={<ReasonMaster />}
          />

          <Route
            path={`${process.env.PUBLIC_URL}/master/addressMaster`}
            element={<AddressMaster />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/master/distributorMapping`}
            element={<DistributorMapping />}
          />

          <Route
            path={`${process.env.PUBLIC_URL}/master/balanceConfirmationReport`}
            element={<BalanceConfirmationReport />}
          />

          <Route
            path={`${process.env.PUBLIC_URL}/userManagement/userLog`}
            element={<UserLog />}
          />

          <Route
            path={`${process.env.PUBLIC_URL}/master/tds194RDeclaration`}
            element={<Tds194RDeclaration />}
          />

          <Route
            path={`${process.env.PUBLIC_URL}/master/tds194RDeclarationReport`}
            element={<Tds194RDeclarationReport />}
          />

          <Route
            path={`${process.env.PUBLIC_URL}/reports/ledger`}
            element={<Ledger />}
          />

          <Route
            path={`${process.env.PUBLIC_URL}/master/balanceConfirmationHistory`}
            element={<BalanceConfirmationHistory />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/reports/ageingReport`}
            element={<AgeingReport />}
          />
        </Route>
      </Routes>
    </Fragment>
  );
};

export default LayoutRoutes;
