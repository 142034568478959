import React, { Fragment, useEffect } from "react";
import { Tabs, TabPanel } from "react-tabs";
import { useNavigate } from "react-router-dom";
import { Button, Form, FormGroup, Input, Media } from "reactstrap";
import {
  Box,
  CircularProgress,
  FormHelperText,
  Typography,
} from "@mui/material";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from "react-simple-captcha";
import stats from "../../assets/images/Modenik_logo.png";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material";
import { changePassword, loginAuth, updateLoginPin } from "../api/service";
import { ToastContainer, toast } from "react-toastify";
import { setToken } from "../../utils/helper";

const useStyles = makeStyles((theme) => ({
  h33: {
    textAlign: "center",
    fontWeight: 700,
    fontSize: "1.4rem",
    lineHeight: 4.5,
  },

  forgot: {
    textAlign: "end",
    fontWeight: 600,
    fontSize: "1rem",
    color: "#f91e26",
    cursor: "pointer",
    marginTop: "-1vh",
    marginBottom: "2vh",
  },
  login: {
    marginTop: theme.spacing(1.5),
    fontWeight: 400,
    padding: theme.spacing(1.375, 5.625), //'11px 45px',
    background: "black!important",
    border: "none!important",
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
    marginTop: "3vh",
    borderRadius: theme.spacing(0.75),
    lineHeight: 1.8,
    "&:hover": {
      background: "black!important",
    },
    "&:focus": {
      background: "black!important",
    },
  },

  logoImg: {
    width: "35vh",
    margin: "auto",
    marginTop: "1vh",
    [theme.breakpoints.down("sm")]: {
      width: "25vh",
      marginTop: "3vh",
    },
  },

  captchaInput: {
    width: "12vw",
    margin: "auto",
    marginTop: "1vh",
    background: "white!important",
    [theme.breakpoints.down("sm")]: {
      width: "45vw",
    },
    [theme.breakpoints.between("sm", "lg")]: {
      width: "20vw",
    },
  },
  captchaBox: {
    background: "#efefef",
    width: "50%",
    margin: "auto",
    padding: theme.spacing(2.5, 0), //'20px 0px',
    borderRadius: theme.spacing(0.75),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.down("lg")]: {
      width: "100%",
    },
  },

  captchaError: {
    color: "red!important",
    fontSize: `${theme.spacing(1.75)} !important`,
    marginTop: "1vh!important",
  },
}));

const LoginTabset = () => {
  const history = useNavigate();
  const classes = useStyles();
  const theme = useTheme();

  const initialValues = {
    username: "",
    password: "",
    captcha: "",
  };

  const cpinitialValues = {
    newPassword: "",
    confirmNewPassword: "",
  };

  const [loading, setLoading] = React.useState(false);
  const [values, setValues] = React.useState(initialValues);

  const [changePasswordValues, setChangePasswordValues] =
    React.useState(cpinitialValues);

  const [isValidCaptcha, setIsValidCaptcha] = React.useState(true);
  const [showChangePasswordSection, setShowChangePasswordSection] =
    React.useState(false);

  useEffect(() => {
    loadCaptchaEnginge(6, "grey", "#000101", "numbers");
  }, []);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setValues({
      ...values,
      [e.target.name]: value,
    });
  };

  const handleInputChangePassword = (e) => {
    const value = e.target.value;
    setChangePasswordValues({
      ...changePasswordValues,
      [e.target.name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    if (validateCaptcha(values.captcha) == true) {
      setIsValidCaptcha(true);
      loginAuth(values)
        .then((req) => {
          if (req.response.responseCode === true) {
            if(req.response.token) {
              setToken(req.response.token);
            } 
            setLoading(false);
            sessionStorage.setItem(
              "userData",
              JSON.stringify(req.response.responseObject[0])
            );
            //console.log(req.response.responseObject[0]);
            if (
              req.response.responseObject[0].passwordChangeDate != null &&
              req.response.responseObject[0].passwordChangeDate !== ""
            ) {
              history(`${process.env.PUBLIC_URL}/dashboard`);
            } else {
              setShowChangePasswordSection(true);
            }
          } else {
            setLoading(false);
            toast.warning("Please verify your username or password!", {
              autoClose: 1000,
            });
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      setIsValidCaptcha(false);
      setLoading(false);
    }
  };

  const handleSubmitChangePassword = (event) => {
    event.preventDefault();

    if (
      changePasswordValues.newPassword !=
      changePasswordValues.confirmNewPassword
    ) {
      alert("New Password and Confirm new Password are mismatched");
      return false;
    } else {
      setLoading(true);
      const userData = JSON.parse(sessionStorage.getItem("userData"));
      const isDistributorUser =
        JSON.parse(sessionStorage.getItem("userData")).groupId === 0;

      if (isDistributorUser) {
        updateLoginPin(
          JSON.parse(sessionStorage.getItem("userData")).userId,
          changePasswordValues.newPassword
        )
          .then((req) => {
            if (req.response.responseCode === true) {
              userData.password = changePasswordValues.newPassword;
              sessionStorage.setItem("userData", JSON.stringify(userData));
              setLoading(false);
              history(`${process.env.PUBLIC_URL}/dashboard`);
            } else {
              setLoading(false);
              toast.warning("Something went wrong", {
                autoClose: 1000,
              });
            }
          })
          .catch((error) => {
            setLoading(false);
          });
      } else {
        changePassword(
          JSON.parse(sessionStorage.getItem("userData")).userId,
          changePasswordValues.newPassword
        )
          .then((req) => {
            if (req.response.responseCode === true) {
              setLoading(false);
              userData.password = changePasswordValues.newPassword;
              sessionStorage.setItem("userData", JSON.stringify(userData));
              history(`${process.env.PUBLIC_URL}/dashboard`);
            } else {
              setLoading(false);
              toast.warning("Something went wrong", {
                autoClose: 1000,
              });
            }
          })
          .catch((error) => {
            setLoading(false);
          });
      }
    }
  };

  return (
    <div>
      <Fragment>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "fixed",
              top: "50%",
              left: "50%",
              zIndex: "10",
              transform: "translate(-50%, -50%)",
              width: "20%",
            }}
          >
            <CircularProgress disableShrink />
            <Typography
              component="span"
              sx={{ position: "absolute", top: theme.spacing(5.25) }}
            >
              <b>Loading...</b>
            </Typography>
          </div>
        ) : null}
        <Tabs>
          <Media alt="" src={stats} className={classes.logoImg} />
          {!showChangePasswordSection && (
            <>
              <Box component="h4" className={classes.h33}>
                Login Page
              </Box>
              <TabPanel>
                <Form
                  className="form-horizontal auth-form"
                  method="post"
                  noValidate=""
                  onSubmit={handleSubmit}
                >
                  <FormGroup>
                    <Input
                      required={true}
                      name="username"
                      id="username"
                      type="text"
                      value={values.username}
                      onChange={handleInputChange}
                      className="form-control"
                      placeholder="Username"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Input
                      name="password"
                      id="password"
                      type="password"
                      value={values.passsword}
                      onChange={handleInputChange}
                      className="form-control"
                      placeholder="Password"
                      required={true}
                      minLength="4"
                    />
                  </FormGroup>

                  <Box className={classes.forgot}>Forgot Password?</Box>

                  <Box component="div" className={classes.captchaBox}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <LoadCanvasTemplate
                        reloadText={"Reload Captcha"}
                        reloadColor="#211f1f"
                      />
                    </Box>

                    <Input
                      name="captcha"
                      id="captcha"
                      type="text"
                      value={values.captcha}
                      onChange={handleInputChange}
                      className={classes.captchaInput}
                      placeholder="Enter Captcha"
                      required={true}
                      minLength="6"
                    />
                    {!isValidCaptcha && (
                      <FormHelperText className={classes.captchaError}>
                        Captcha Does Not Match
                      </FormHelperText>
                    )}
                  </Box>

                  <div className="form-button">
                    <Button className={classes.login} type="submit">
                      Login
                    </Button>
                  </div>
                </Form>
              </TabPanel>
            </>
          )}
          {showChangePasswordSection && (
            <>
              <Box component="h4" className={classes.h33}>
                Change Password
              </Box>
              <TabPanel>
                <Form
                  className="form-horizontal auth-form"
                  method="post"
                  noValidate=""
                  onSubmit={handleSubmitChangePassword}
                >
                  <FormGroup>
                    <Input
                      required={true}
                      name="newPassword"
                      id="newPassword"
                      type="password"
                      value={changePasswordValues.newPassword}
                      onChange={handleInputChangePassword}
                      className="form-control"
                      placeholder="New Password"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Input
                      name="confirmNewPassword"
                      id="confirmNewPassword"
                      type="password"
                      value={changePasswordValues.confirmNewPassword}
                      onChange={handleInputChangePassword}
                      className="form-control"
                      placeholder="Confirm New Password"
                      required={true}
                      minLength="4"
                    />
                  </FormGroup>

                  <div className="form-button">
                    <Button className={classes.login} type="submit">
                      Change Password
                    </Button>
                  </div>
                </Form>
              </TabPanel>
            </>
          )}
        </Tabs>
        <ToastContainer autoClose={1000} />
      </Fragment>
    </div>
  );
};

export default LoginTabset;
