import { getValue, setValue } from './localStorage';
// import history from '../routes/history';
import { isEmpty, get } from '../utils/lodash';
// import JsonWebToken from 'jsonwebtoken';

export const getToken = () => getValue('_modenik_token');

export const setToken = (value) => setValue('_modenik_token', value);

export const getCookie = (cookie_name) => getValue(cookie_name)

// export const forwardTo = (location) => history.push(location);

// export const forwardToWithState = (location, state) => history.push(location, state);

export const formatString = (value) => {
  if (isEmpty(value) || value === '_') {
    return '--';
  }
  return value.replace(/[ +*/.,<>%?:;|&#()\\{}"\[\]]/g, '');
};

export const capitalizeFirstLetter = (value) =>
  value ? value.slice(0, 1).toUpperCase() + value.slice(1).toLowerCase() : value;


