import axios from "axios";
import { getToken } from "../../utils/helper";

//const url = "http://localhost:8090/service/";
//const url = "http://13.126.15.195:8080/modenik/service/";
const url = "https://modeniktransparency.in/modenik/service/";

const instance = axios.create({
  baseURL: url,
});

// Request Interceptor (if needed to modify headers)
instance.interceptors.request.use(
  (config) => {
    const token = getToken(); // Replace with your token retrieval logic
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
      config.headers["Request-From"] = `Web`;
    }
    config.headers["Content-Type"] = "application/json"; // Add default headers if required
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => res,
  (err) => {
    console.log("Error Status =>", err?.response?.status);
    if (err?.response?.status === 401) {
      //const loginURL = "http://localhost:3000";
      const loginURL = "https://modeniktransparency.in/";

      sessionStorage.clear();
      window.location.href = loginURL; // Redirect to appropriate login page
    }
    console.log("Error Interceptor =>", err.response);
    // alert("Kindly try again in some time.")
    // alert(err.response.data);
    //return err;
    return Promise.reject(err);
  }
);

export default instance;
