import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import CommonDialog from "../../common/commonDialog";
import { Card, CardBody } from "reactstrap";
import { Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import { formatMoney, getDivisionName } from "../../../constants/utils";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
const AgeingReportModal = (props) => {
  const { open, onCancel, modalData, modalDataWithoutDivision, title } = props;
  const [value, setValue] = useState("1");
  const [isFilterByDivision, setIsFilterByDivision] = useState("Yes");
  const [tableData, setTableData] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    const tableData =
      isFilterByDivision === "Yes" ? modalData : modalDataWithoutDivision;
    setTableData(tableData);
  }, [isFilterByDivision]);

  const sortedData = tableData
    .sort((a, b) => b.overdue - a.overdue)
    .slice(0, 10);

  return (
    <CommonDialog
      onCancel={onCancel}
      open={open}
      secondaryButtonLabel={"Cancel"}
      title={title}
      maxWidth="lg"
    >
      <FormControl>
        <FormLabel id="demo-row-radio-buttons-group-label">
          Filter By Division
        </FormLabel>
        <RadioGroup
          row
          value={isFilterByDivision}
          name="row-radio-buttons-group"
          onChange={(event) => {
            setIsFilterByDivision(event.target.value);
          }}
        >
          <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
          <FormControlLabel value="No" control={<Radio />} label="No" />
        </RadioGroup>
      </FormControl>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Live Data" value="1" />
            <Tab label="Top 10 Overdue Party" value="2" />
            {/* <Tab label="Item Three" value="3" /> */}
          </TabList>
        </Box>
        <TabPanel value="1">
          <Grid container spacing={2}>
            <Col lg={12} xs={12}>
              <Card className="height-equal">
                <CardBody>
                  <div className="user-status table-responsive text-center">
                    <table className="table table-bordernone mb-0 mt-10">
                      <thead>
                        <tr>
                          <th scope="col">SNO</th>

                          <th scope="col">Customer</th>
                          <th scope="col">Customer Code</th>
                          {isFilterByDivision === "Yes" && (
                            <th scope="col">Division</th>
                          )}
                          <th scope="col">Net Balance</th>
                          <th scope="col">Over due</th>
                          <th scope="col">Not Over due</th>
                          <th scope="col">Security Deposit</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData?.map((data, index) => (
                          <tr key={data.customerCode}>
                            <td>{index + 1}</td>

                            <td>{data.customerName}</td>
                            <td>{data.customerCode}</td>
                            {isFilterByDivision === "Yes" && (
                              <td>{getDivisionName(data.division)}</td>
                            )}
                            <td>{formatMoney(data.netBalance?.toFixed(0))}</td>
                            <td>{formatMoney(data.overdue?.toFixed(0))}</td>
                            <td>
                              {data.netBalance > 0 && data.overdue > 0
                                ? formatMoney(
                                    (data.netBalance - data.overdue)?.toFixed(0)
                                  )
                                : "-"}
                            </td>
                            <td>
                              {formatMoney(data.securityDeposit?.toFixed(0))}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Grid>
        </TabPanel>
        <TabPanel value="2">
          <Grid container spacing={2}>
            <Col lg={12} xs={12}>
              <Card className="height-equal">
                <CardBody>
                  <div className="user-status table-responsive text-center">
                    <table className="table table-bordernone mb-0 mt-10">
                      <thead>
                        <tr>
                          <th scope="col">SNO</th>
                          <th scope="col">Customer</th>
                          <th scope="col">Customer Code</th>
                          {isFilterByDivision === "Yes" && (
                            <th scope="col">Division</th>
                          )}
                          <th scope="col">Net Balance</th>
                          <th scope="col">Over due</th>
                          <th scope="col">Not Over due</th>
                          <th scope="col">Security Deposit</th>
                        </tr>
                      </thead>
                      <tbody>
                        {sortedData?.map((data, index) => (
                          <tr key={data.customerCode}>
                            <td>{index + 1}</td>

                            <td>{data.customerName}</td>
                            <td>{data.customerCode}</td>
                            {isFilterByDivision === "Yes" && (
                              <td>{getDivisionName(data.division)}</td>
                            )}
                            <td>{formatMoney(data.netBalance?.toFixed(0))}</td>
                            <td>{formatMoney(data.overdue?.toFixed(0))}</td>
                            <td>
                              {data.netBalance > 0 && data.overdue > 0
                                ? formatMoney(
                                    (data.netBalance - data.overdue)?.toFixed(0)
                                  )
                                : "-"}
                            </td>
                            <td>
                              {formatMoney(data.securityDeposit?.toFixed(0))}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Grid>
        </TabPanel>
        <TabPanel value="3">Item Three</TabPanel>
      </TabContext>
    </CommonDialog>
  );
};
export default AgeingReportModal;
