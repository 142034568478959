import React, { Fragment, useState } from "react";
import Breadcrumb from "../../common/breadcrumb";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Media,
  Row,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { makeStyles } from "@mui/styles";
import AgeingReportModal from "./ageingReportModal";
import CommonTable from "../../common/commonTable";
import {
  agingReportbyRegionTableData,
  agingReportbyRegionTableHeader,
  agingReportbyStateTableData,
  agingReportbyStateTableHeader,
  totalOverdueByRegionTableListBody,
} from "../../common/commonConstant";
import { useEffect } from "react";
import {
  getAgeingReportDetailsByRegion,
  getAgeingReportDetailsByState,
  getConsolidateAgeingReport,
} from "../../api/service";
import CommonLoader from "../../common/commonLoader";
import moment from "moment";
import { Bar, Line } from "react-chartjs-2";
import { Chart } from "react-google-charts";
import { formatMoney } from "../../../constants/utils";
const useStyles = makeStyles((theme) => ({
  btnClass: {
    padding: "0.5rem 1.5rem!important",
    fontWeight: 600,
  },

  btnJust: {
    display: "flex!important",
    justifyContent: "end!important",
  },

  cardbody: {
    padding: `${theme.spacing(1.8, 1.3)} !important`,
    [theme.breakpoints.down("sm")]: {
      padding: `${theme.spacing(0)} !important`,
      margin: `${theme.spacing(0, 1)} !important`,
    },
  },

  muiGrid: {
    marginTop: "-2vh",
    [theme.breakpoints.down("sm")]: {
      lineHeight: 5,
    },
  },
}));

const status = [
  {
    value: "Pending",
    label: "Pending",
  },
  {
    value: "Approved",
    label: "Approved",
  },
  {
    value: "Reject",
    label: "Reject",
  },
];

const AgeingReport = () => {
  /*  const lineData = {
    labels: ["West", "East", "North", "South"],
    datasets: [
      {
        data: [2.5, 3, 3, 0.9, 1.3, 1.8, 3.8, 1.5],
        borderColor: "#ff8084",
        backgroundColor: "#ff8084",
        borderWidth: 2,
        barPercentage: 0.7,
        categoryPercentage: 0.4,
      },
      {
        data: [3.8, 1.8, 4.3, 2.3, 3.6, 2.8, 2.8, 2.8],
        borderColor: "#a5a5a5",
        backgroundColor: "#a5a5a5",
        borderWidth: 2,
        barPercentage: 0.7,
        categoryPercentage: 0.4,
      },
    ],
  }; */

  const barChartDataByRegionOptions = {
    maintainAspectRatio: false,
    responsive: true,

    animation: true,
    plugins: {
      legend: {
        display: true,
      },
    },
    scales: {
      xAxes: {
        barPercentage: 0.7,
        categoryPercentage: 0.4,
        gridLines: {
          display: false,
        },
      },
      yAxes: {
        barPercentage: 0.7,
        categoryPercentage: 0.4,
      },
    },
    /* scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
      },
    }, */
  };

  const lineOptionsByState = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  const classes = useStyles();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const [agingReportDataByRegion, setAgingReportDataByRegion] = useState([]);
  const [agingReportDataByState, setAgingReportDataByState] = useState([]);
  const [totalOverdueByRegionByMonthList, setTotalOverdueByRegionByMonthList] =
    useState([]);
  const [
    totalOverdueByRegionTableListHeader,
    setTotalOverdueByRegionTableListHeader,
  ] = useState([]);
  const [
    totalOverdueByRegionTableListData,
    setTotalOverdueByRegionTableListData,
  ] = useState([]);

  const [modalData, setModalData] = useState([]);
  const [modalDataWithoutDivision, setModalDataWithoutDivision] = useState([]);
  const [rowData, setRowData] = useState(null);
  const [isRegion, setIsRegion] = useState(false);
  const [loading, setLoading] = useState(false);

  const labels = agingReportDataByRegion.map((item) => item.regionName);
  const statelabels = agingReportDataByState.map((item) => item.stateName);

  const lineDataByState = {
    statelabels,
    datasets: [
      {
        label: "Total Net Balance",
        data: agingReportDataByState.map((item) => item.totalNetBalance),
        backgroundColor: "rgba(75, 192, 192, 0.6)", // Greenish-blue color
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  const barChartDataByDataRegion = {
    labels,
    datasets: [
      {
        label: "Overdue",
        data: agingReportDataByRegion.map((item) => item.totalOverdue),
        borderColor: "#da0000",
        backgroundColor: "#da0000",
        //backgroundColor: "rgb(218, 0, 0)",
        borderWidth: 2,
      },
      {
        label: "Days 0-30",
        data: agingReportDataByRegion.map((item) => item.totalDays0_30),
        borderColor: "#2a863f",
        backgroundColor: "#2a863f",
        borderWidth: 2,
      },
      {
        label: "Days 31-45",
        data: agingReportDataByRegion.map((item) => item.totalDays31_45),
        borderColor: "#d69e11",
        backgroundColor: "#d69e11",
        borderWidth: 2,
      },
      {
        label: "Days 46-61",
        data: agingReportDataByRegion.map((item) => item.totalDays46_60),
        borderColor: "#b255ec",
        backgroundColor: "#b255ec",
        borderWidth: 2,
      },
      {
        label: "Days 61-90",
        data: agingReportDataByRegion.map((item) => item.totalDays61_90),
        borderColor: "#0e87d7",
        backgroundColor: "#0e87d7",
        borderWidth: 2,
      },
      {
        label: "Days 91-180",
        data: agingReportDataByRegion.map((item) => item.totalDays91_180),
        borderColor: "#111b75",
        backgroundColor: "#111b75",
        borderWidth: 2,
      },
      {
        label: "Days 181-365",
        data: agingReportDataByRegion.map((item) => item.totalDays181_365),
        borderColor: "#65233b",
        backgroundColor: "#65233b",
        borderWidth: 2,
      },
      {
        label: "Days >365",
        data: agingReportDataByRegion.map(
          (item) => item.totalDaysGreaterThan_365
        ),
        borderColor: "#da4100",
        backgroundColor: "#da4100",
        //backgroundColor: "rgb(218, 65, 0)",
        borderWidth: 2,
      } /* 
      {
        label: "Net Balance",
        data: agingReportDataByRegion.map((item) => item.totalNetBalance),
        borderColor: "#ff8084",
        backgroundColor: "#ff8084",
        borderWidth: 2,
      }, */,
    ],
  };

  /* const buyData = {
    labels: ["", "10", "20", "30", "40", "50"],
    datasets: [
      {
        backgroundColor: "transparent",
        borderColor: "#13c9ca",
        data: [20, 5, 80, 10, 100, 15],
        lineTension: 0.4,
      },
      {
        backgroundColor: "transparent",
        borderColor: "#a5a5a5",
        data: [0, 50, 20, 70, 30, 27],
        lineTension: 0.4,
      },
      {
        backgroundColor: "transparent",
        borderColor: "#ff8084",
        data: [0, 30, 40, 10, 86, 40],
        lineTension: 0.4,
      },
    ],
  }; */

  const processChartData = (data) => {
    // Extract unique months (x-axis labels)
    const months = [...new Set(data.map((item) => item.monthName))];

    // Extract unique regions
    const regions = [...new Set(data.map((item) => item.regionName))];

    // Create datasets for each region
    const datasets = regions.map((region) => ({
      label: region,
      backgroundColor: "transparent",
      borderColor: getColor(region),
      pointBackgroundColor: getColor(region),
      data: months.map((month) => {
        const entry = data.find(
          (item) => item.monthName === month && item.regionName === region
        );
        return entry ? entry.totalOverdue : 0; // Default to 0 if missing
      }),
      lineTension: 0.4,
    }));
    return { labels: months, datasets };
  };

  // Function to assign colors dynamically
  const getColor = (region) => {
    const colorMap = {
      West: "#0e87d7",
      North: "#b255ec",
      East: "#a6b90e",
      South: "#d7600e",
    };
    return colorMap[region];
  };

  const lineChartByFinancialYearData = processChartData(
    totalOverdueByRegionByMonthList
  );

  useEffect(() => {
    console.log(lineChartByFinancialYearData);
    if (
      lineChartByFinancialYearData?.datasets &&
      lineChartByFinancialYearData?.datasets.length > 0
    ) {
      const newTitle = ["Region", ...lineChartByFinancialYearData.labels];

      setTotalOverdueByRegionTableListHeader((prevTitle) =>
        JSON.stringify(prevTitle) !== JSON.stringify(newTitle)
          ? newTitle
          : prevTitle
      );
      const transformedData = lineChartByFinancialYearData?.datasets.map(
        (item) => {
          return {
            regionName: item.label,
            marMonthVal: formatMoney(item.data[0]),
            febMonthVal: formatMoney(item.data[1]),
            janMonthVal: formatMoney(item.data[2]),
            decMonthVal: formatMoney(item.data[3]),
            novMonthVal: formatMoney(item.data[4]),
            octMonthVal: formatMoney(item.data[5]),
            sepMonthVal: formatMoney(item.data[6]),
            augMonthVal: formatMoney(item.data[7]),
            julMonthVal: formatMoney(item.data[8]),
            junMonthVal: formatMoney(item.data[9]),
            mayMonthVal: formatMoney(item.data[10]),
            aprMonthVal: formatMoney(item.data[11]),
          };
        }
      );

      setTotalOverdueByRegionTableListData((prevData) =>
        JSON.stringify(prevData) !== JSON.stringify(transformedData)
          ? transformedData
          : prevData
      );
    }
  }, [lineChartByFinancialYearData]);

  const lineChartByFinancialYearOption = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        labels: {
          usePointStyle: true, // Enables circular legend icons
          boxWidth: 10, // Adjusts size
          color: "#000",
        },
      },
    },
    scales: {
      xAxes: {
        gridLines: {
          color: "#f8f8f8",
        },
      },
      yAxes: {
        gridLines: {
          color: "#f8f8f8",
        },
      },
    },
  };

  const pieOptions = {
    title: "",
    pieHole: 1,
    slices: [
      {
        color: "#ff8084",
      },
      {
        color: "#13c9ca",
      },
      {
        color: "#f0b54d",
      },
    ],
    tooltip: {
      showColorCode: false,
    },
    chartArea: { left: 0, top: 10, width: "360px", height: "100%" },
    legend: "none",
  };

  useEffect(() => {
    loadListData();
  }, []);

  const loadListData = () => {
    setLoading(true);
    getConsolidateAgeingReport(page, rowsPerPage).then((res) => {
      setLoading(false);
      setAgingReportDataByRegion(res.response.responseMap.dtoListByRegion);
      setAgingReportDataByState(res.response.responseMap.dtoListByState);
      setTotalOverdueByRegionByMonthList(
        res.response.responseMap.totalOverdueByRegionByMonthList
      );
    });
  };
  const [open, setOpen] = useState(false);

  const onOpenModalRegion = (row) => {
    setIsRegion(true);
    if (row.regionMasterId) {
      setLoading(true);
      getAgeingReportDetailsByRegion(row.regionMasterId).then((res) => {
        setLoading(false);
        setModalData(
          res.response.responseMap.ageingReportsWithDivisionByRegion
        );
        setModalDataWithoutDivision(
          res.response.responseMap.ageingReportsWithoutDivisionByRegion
        );
        //setOpen
        setRowData(row);
        setOpen(true);
      });
    }
  };

  const onOpenModalState = (row) => {
    setIsRegion(false);
    if (row.stateMasterId) {
      setLoading(true);
      getAgeingReportDetailsByState(row.stateMasterId).then((res) => {
        setLoading(false);
        setModalData(res.response.responseObject);
        setRowData(row);
        setOpen(true);
      });
    }
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const slabels = agingReportDataByState.map((item) => item.stateName);
  const filteredDataByState = agingReportDataByState.filter(
    (item) => item.totalNetBalance > 0
  );
  const netBalancesByState = filteredDataByState.map(
    (item) => item.totalNetBalance
  );
  // 27 unique background colors
  const backgroundColors = [
    "#1a139f",
    "#ff6384",
    "#36a2eb",
    "#ffce56",
    "#4bc0c0",
    "#9966ff",
    "#ff9f40",
    "#e74c3c",
    "#2ecc71",
    "#f39c12",
    "#9b59b6",
    "#8e44ad",
    "#2c3e50",
    "#d35400",
    "#16a085",
    "#c0392b",
    "#7f8c8d",
    "#e67e22",
    "#2980b9",
    "#f1c40f",
    "#27ae60",
    "#34495e",
    "#dc7633",
    "#f1948a",
    "#5dade2",
    "#58d68d",
    "#d5dbdb",
  ].slice(0, slabels.length);
  const barDataByState = {
    labels: slabels,
    datasets: [
      {
        label: "Net Balance",
        data: netBalancesByState,
        backgroundColor: backgroundColors, //"#1a139f", // Greenish-blue color
        borderColor: backgroundColors, //,"#1a139f", // Greenish-blue color
        //borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  const barOptionsByState = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  const pieOptionsForState = {
    title: "Overdue by State",
    titleTextStyle: { fontSize: 16, bold: true }, // Customize title style
    pieHole: 0.4, // Semi-donut effect
    slices: agingReportDataByState.map((_, index) => ({
      color: backgroundColors[index],
    })),
    tooltip: { showColorCode: true },
    chartArea: { left: 10, top: 10, width: "100%", height: "100%" },
    //legend: { position: "right" },
    legend: "none", // Hides the legend
  };

  const pieChartData = [
    ["State", "Total Overdue"],
    ...agingReportDataByState.map(({ stateName, totalOverdue }) => [
      stateName,
      totalOverdue,
    ]),
  ];

  return (
    <Fragment>
      {loading ? <CommonLoader /> : null}
      <Breadcrumb title="Aging Report" />
      {/* <!-- Container-fluid starts--> */}
      <Container fluid={true}>
        <Row>
          <Card>
            <CardHeader
              style={{
                padding: "10px 10px 0px",
              }}
            >
              <h5>By Region</h5>
            </CardHeader>
            <CardBody className={classes.cardbody}>
              <Row>
                <Col sm="12">
                  <Card>
                    <h5>Trend for last Financial Year (FY 2024-25)</h5>
                    <CardBody className="sell-graph">
                      <Line
                        data={lineChartByFinancialYearData}
                        options={lineChartByFinancialYearOption}
                        width={700}
                        height={350}
                      />
                    </CardBody>
                  </Card>
                </Col>
                <Col sm="12">
                  <Card>
                    <CardBody className="sell-graph">
                      <CommonTable
                        tableData={totalOverdueByRegionTableListData}
                        tableHeaders={totalOverdueByRegionTableListHeader}
                        tableColumns={totalOverdueByRegionTableListBody}
                        //editId={"regionMasterId"}
                        //editMode={true}
                        onOpenModal={onOpenModalRegion}
                        rowsPerPageOptions={[25, 50, 100]}
                        count={totalOverdueByRegionTableListData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        displayPagination={false}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <h5>Live Details</h5>
              <div className="market-chart">
                <Bar
                  data={barChartDataByDataRegion}
                  options={barChartDataByRegionOptions}
                  width={778}
                  height={308}
                />
              </div>
              <div>
                <CommonTable
                  tableData={agingReportDataByRegion}
                  tableHeaders={agingReportbyRegionTableHeader}
                  tableColumns={agingReportbyRegionTableData}
                  editId={"regionMasterId"}
                  editMode={true}
                  onOpenModal={onOpenModalRegion}
                  rowsPerPageOptions={[25, 50, 100]}
                  count={agingReportDataByRegion.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  sx={{ float: "right" }}
                />
              </div>
            </CardBody>
          </Card>
        </Row>
        <Row>
          <Card>
            <CardHeader
              style={{
                padding: "10px 30px 0px",
              }}
            >
              <h5>By State</h5>
            </CardHeader>
            <CardBody className={classes.cardbody}>
              <div className="market-chart">
                <Bar
                  data={barDataByState}
                  options={barOptionsByState}
                  width={778}
                  height={308}
                />
              </div>
              <div>
                <CommonTable
                  tableData={agingReportDataByState}
                  tableHeaders={agingReportbyStateTableHeader}
                  tableColumns={agingReportbyStateTableData}
                  editId={"stateMasterId"}
                  editMode={true}
                  onOpenModal={onOpenModalState}
                  rowsPerPageOptions={[25, 50, 100]}
                  count={agingReportDataByState.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  sx={{ float: "right" }}
                />
              </div>
            </CardBody>
          </Card>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>Live Data by State</h5>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xl="8 xl-50" sm="12">
                    <div className="order-graph sm-order-space">
                      <div className="peity-chart-dashboard text-center">
                        <Chart
                          chartType="PieChart"
                          data={pieChartData}
                          options={pieOptionsForState}
                          graph_id="PieChart"
                          width={"700px"} // Increase width
                          height={"500px"}
                          //legend_toggle
                        />
                      </div>
                    </div>
                  </Col>
                  <Col
                    xl="4 xl-50"
                    sm="12"
                    //style={{ height: 500, overflow: "scroll" }}
                  >
                    <div className="order-graph sm-order-space">
                      <Media body>
                        <h6 className="mb-0 me-0">
                          {`State`}
                          <span className="pull-right">{`Overdue`}</span>
                        </h6>
                      </Media>
                      <div
                        className="order-graph-bottom sales-location"
                        style={{ height: 500, overflow: "scroll" }}
                      >
                        {agingReportDataByState.map((item, index) => {
                          return (
                            <Media>
                              <div
                                className="order-shape-primary"
                                style={{
                                  backgroundColor: backgroundColors[index],
                                }}
                              ></div>
                              <Media body>
                                <h6 className="mb-0 me-0">
                                  {`${item.stateName} `}
                                  <span className="pull-right">
                                    {formatMoney(item.totalOverdue)}
                                  </span>
                                </h6>
                              </Media>
                            </Media>
                          );
                        })}
                        {/*  <Media>
                          <div className="order-shape-primary"></div>
                          <Media body>
                            <h6 className="mb-0 me-0">
                              Germany <span className="pull-right">25%</span>
                            </h6>
                          </Media>
                        </Media>
                        <Media>
                          <div className="order-shape-secondary"></div>
                          <Media body>
                            <h6 className="mb-0 me-0">
                              Brasil <span className="pull-right">10%</span>
                            </h6>
                          </Media>
                        </Media>
                        <Media>
                          <div className="order-shape-danger"></div>
                          <Media body>
                            <h6 className="mb-0 me-0">
                              United Kingdom
                              <span className="pull-right">34%</span>
                            </h6>
                          </Media>
                        </Media>
                        <Media>
                          <div className="order-shape-warning"></div>
                          <Media body>
                            <h6 className="mb-0 me-0">
                              Australia<span className="pull-right">5%</span>
                            </h6>
                          </Media>
                        </Media>
                        <Media>
                          <div className="order-shape-success"></div>
                          <Media body>
                            <h6 className="mb-0 me-0">
                              Canada <span className="pull-right">25%</span>
                            </h6>
                          </Media>
                        </Media> */}
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <AgeingReportModal
          open={open}
          onCancel={() => {
            setOpen(false);
            setRowData(null);
            setModalData([]);
            setModalDataWithoutDivision([]);
            setIsRegion(false);
          }}
          modalData={modalData}
          modalDataWithoutDivision={modalDataWithoutDivision}
          title={`${
            rowData && (isRegion ? rowData?.regionName : rowData?.stateName)
          } - Ageing Report`}
        />
      </Container>
      {/* <!-- Container-fluid Ends--> */}
      <ToastContainer autoClose={1000} />
    </Fragment>
  );
};

export default AgeingReport;
